import axios from "axios";
import {getToken} from "../../provider/authProvider";

export default class Api {

    constructor() {
        this.api_token = getToken();
        this.client = null;
        this.api_url = process.env.REACT_APP_API_ENDPOINT;
    }

    init = () => {

        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }else{
            // Navigate to log out
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };


    postLogin = (data) => {
        return this.init().post("/auth/login", data);
    };

    getUserList = (params) => {
        return this.init().get("/application/person", { params: params });
    };

    getApplication = (params) => {
        return this.init().get("/application", { params: params });
    };

    addNewUser = (data) => {
        return this.init().post("/users", data);
    };


    //passport
    addNewPassport = (data) => {
        return this.init().post("/application/person/passport", data);
    };


    //immiCards
    addNewCards = (data) => {
        return this.init().post("/application/person/immi13", data);
    };

}
import {Avatar, Box, createTheme, Paper, ThemeProvider} from "@mui/material";
import * as React from "react";
import {map} from "lodash";
import {LabelWithDetails} from "../../../../components/LabelWithDetails";
import * as people from "../../../../services/utils/people.json";
import {getFamilyMembersDetails} from "../../../../services/utils/helper";
import {PassportListComp} from "../../../../components/passportListComp";
import {ImmiCardsComp} from "../../../../components/immiCardsComp";
import {applicationType, userType} from "../../../../services/utils/types/types";

interface SpouseDetailsProps {
    application?: applicationType;
}

const theme = createTheme();

export const SpouseDetails: React.FC<SpouseDetailsProps> = (props) => {
    let userList: userType[], user: userType | undefined = {};
    if (props && props.application && props.application.person && props.application.person) {
        userList = props.application.person;
        user = userList.find(person => person.relationship ? person.relationship.toLowerCase() === "spouse" : false);
    }


    let immiList: any = [];
    let passportList: any = [];
    let userID ="";
    if(user&& user.id){
        userID = user.id;
    }
    if (user && user.immi13) {
        immiList = Array.from(user.immi13);
    }
    if (user && user.passport) {
        passportList = Array.from(user.passport);
    }
    const spouseDetails = getFamilyMembersDetails(user);


    const emptyDom = <div></div>
    const dom = <ThemeProvider theme={theme}>
        <Box
            sx={{
                m: 2,
            }}
        >
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto',
            }}>
                {map(spouseDetails, (i) => (
                    <LabelWithDetails title={i.title.toUpperCase()}>{i.detail}</LabelWithDetails>
                ))}
            </div>
        </Box>

        {/*List of passports*/}
        <PassportListComp passportList={passportList} personID={userID}></PassportListComp>

        {/*List of IMMI13 cards*/}
        <ImmiCardsComp immiCardList={immiList} personID={userID}></ImmiCardsComp>
    </ThemeProvider>


    if (!(user && user.name && user.name.length > 3)) {
        return emptyDom;
    } else {
        return dom;
    }


};
import * as React from "react";
import { map } from "lodash";
import { Avatar, Box, Button, createTheme, FormLabel, LinearProgress, Paper, ThemeProvider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { RowDetailWithBorder } from "../../components/RowDetailWithBorder";
import Api from "../../services/server/api";
import { useEffect, useRef } from "react";
import { applicationType, userType } from "../../services/utils/types/types";
import { LabelWithDetails } from "../../components/LabelWithDetails";
import { PassportListComp } from "../../components/passportListComp";
import { ImmiCardsComp } from "../../components/immiCardsComp";
import { getAgeByYearOfBirth, getAgeCategoryByYearOfBirth, getFamilyMembersDetails, getGenderByChar } from "../../services/utils/helper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as app from '../../services/server/aa.json';
import Divider from "@mui/material/Divider";

const theme = createTheme();

interface PrintProfileProps {}

export const PrintApplication: React.FC<PrintProfileProps> = (props) => {
    let application: applicationType = {
        applicationStatus: "",
        callingNum: "",
        emailAddress: "",
        mobileNo: "",
        name: "",
        person: []
    };

    const [value, setValue] = React.useState(0);
    const [isLoading, setLoading] = React.useState(false);
    const [applicationData, setApplication] = React.useState(application);

    const queryParameters = new URLSearchParams(window.location.search)
    const appId = queryParameters.get("id");

    const handlePrint = async () => {
        const element = printRef.current;
        if (!element) return;

        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const canvas = await html2canvas(element);
        const totalHeight = canvas.height;
        const totalPages = Math.ceil(totalHeight / pdfHeight);

        for (let page = 0; page < totalPages; page++) {
            const canvasSection = document.createElement('canvas');
            canvasSection.width = canvas.width;
            canvasSection.height = pdfHeight * (canvas.width / pdfWidth);

            const context = canvasSection.getContext('2d');
            if (context) {
                context.drawImage(
                    canvas,
                    0,
                    page * pdfHeight * (canvas.width / pdfWidth),
                    canvas.width,
                    pdfHeight * (canvas.width / pdfWidth),
                    0,
                    0,
                    canvas.width,
                    pdfHeight * (canvas.width / pdfWidth)
                );
                const imgData = canvasSection.toDataURL('image/png');
                if (page > 0) pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            }
        }

        pdf.save('application.pdf');
        window.history.go(-1);

    };

    const getApplication = (params: any) => {
        setLoading(true);
        const api = new Api();
        api
            .getApplication(params)
            .then((response) => {
                application = response.data[0];
                setApplication(application);
                setLoading(false);
                handlePrint();
            })
            .catch((err) => {
                console.log(err);
                setApplication(application);
                setLoading(false);
            });
    };


    useEffect(() => {
        getApplication({id: appId});
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // @ts-ignore
    let userList: userType[] | [] = applicationData.person;

    let newArr = userList.map((element: userType, index) => {
        const item = {
            doms: getFamilyMembersDetails(element),
            immi13: element.immi13,
            passport: element.passport,
            userID: element.id
        }
        return item;
    });

    let immiList: any = [];
    let passportList: any = [];
    let userID = "";


    const printRef = useRef<HTMLDivElement>(null);

    return (
        <ThemeProvider theme={theme}>

{/*
            <Button variant="contained" color="primary" onClick={handlePrint}>Generate PDF</Button>
*/}
            <div id="pdf-content" ref={printRef} style={{ padding: '20px' }}>
                <header>
                    <Typography variant="h4">MALAYSIAN HUMANITARIAN AID AND RELIEF (MAHAR)</Typography>
                    <Typography variant="h6">Profile Details of Syrian Migrants Temporary Relocation Programme Candidate</Typography>
                    <Divider variant="fullWidth" sx={{ borderBottomWidth: 2, margin: "20px 0" }} />
                </header>
                {map(newArr, (item, index) => (
                    <div key={index}>
                        <FormLabel sx={{ m: 2 }}>Family Member {index + 1}</FormLabel>
                        <Box sx={{ m: 2 }}>
                            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto' }}>
                                {map(item.doms, (i, idx) => (
                                    <LabelWithDetails key={idx} title={i.title.toUpperCase()}>{i.detail}</LabelWithDetails>
                                ))}
                            </div>
                        </Box>
                        <Divider variant="fullWidth" sx={{ borderBottomWidth: 5 }} />
                    </div>
                ))}
            </div>
        </ThemeProvider>
    );
};

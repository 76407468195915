import * as React from "react";
import {
    Avatar, Box, Button, Checkbox,
    createTheme, Divider, FormControlLabel, Grid, Link, Paper, styled, TextField,
    ThemeProvider, Typography, useTheme,
} from "@mui/material";
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import TrendingUp from "@mui/icons-material/TrendingUp";

interface AdminDashboardProps {

}

const theme = createTheme();

const barChartOptions: ApexOptions = {
    chart: {
        type: 'bar',
            height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
                columnWidth: '55%',
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
            width: 2,
            colors: ['transparent']
    },
    xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    },
    yaxis: {
        title: {
            text: 'Data'
        }
    },
    fill: {
        opacity: 1
    },
}

const chartOptions: ApexOptions = {
    chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
            show: false
        }
    },
    plotOptions: {
        pie: {
            donut: {
                size: '60%'
            }
        }
    },
    colors: ['#ff9900', '#1c81c2', '#333', '#5c6ac0'],
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val + '%';
        },
        background: {
            enabled: true,
            padding: 8,
            borderRadius: 4,
            borderWidth: 0,
            opacity: 0,
            dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.5
            }
        },
        dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.5
        }
    },
    fill: {
        opacity: 1,
    },
    labels: ['Bitcoin', 'Ripple', 'Cardano', 'Ethereum'],
    legend: {
        labels: {
            colors: '#fff'
        },
        show: false
    },
    stroke: {
        width: 0
    },
    theme: {
        mode: theme.palette.mode
    }
};

const lineAreaOption: ApexOptions = {
        chart: {
            height: 350,
            type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        },
}



const lineSeries = [{
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100]
    }, {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41]
    }]
const chartSeries = [10, 20, 25, 45];
const barChartSeries = [{
    name: 'Net Profit',
    data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
}, {
    name: 'Revenue',
    data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
}, {
    name: 'Free Cash Flow',
    data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
}];

const Box2Data = [
    {
        name: 'Ethereum',
        data: [1.854, 1.873, 1.992, 2.009, 1.909, 1.942, 1.884]
    }
];

export const AdminDashboard: React.FC<AdminDashboardProps> = (props) => {


    const Box1Options: ApexOptions = {
        chart: {
            animations: {
                enabled: false
            },
            background: 'transparent',
            toolbar: {
                show: false
            },
            sparkline: {
                enabled: true
            },
            zoom: {
                enabled: false
            }
        },
        labels: [
            'Monday',
            'Tueday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday'
        ],
        stroke: {
            curve: 'smooth',
            width: 2
        },
        yaxis: {
            show: false
        },
        grid: {
            padding: {
                top: 10,
                right: 5,
                bottom: 10,
                left: 5
            }
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            fixed: {
                enabled: true
            },
            x: {
                show: true
            },
            y: {
                title: {
                    formatter: function () {
                        return 'New User';
                    }
                }
            },
            marker: {
                show: false
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Typography
                component="h1"
                variant="h5"
                color="inherit"
                noWrap
                sx={{ my: 4,
                    mx: 4 }}
            >
                Admin Dashboard
            </Typography>
            <Box
                sx={{
                    my: 4,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 200px)'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                    }}>
                    <Paper sx={{
                        mb: 4,
                        mr: 2,
                        width: '25%'
                    }}  elevation={2}>
                        <Box
                            sx={{
                                width: '100%',
                                p: 3
                            }}
                        >
                            <Typography variant="subtitle1" noWrap>
                                Total User
                            </Typography>
                            <Typography variant="h4" noWrap>
                                16,329
                            </Typography>
                        </Box>
                        <Box pt={2}>
                            <Chart
                                options={Box1Options}
                                series={Box2Data}
                                type="line"
                                height={100}
                            />
                        </Box>
                    </Paper>
                    <Paper sx={{
                        mb: 4,
                        mx: 2,
                        width: '25%'
                    }}  elevation={2}>
                        <Box
                            sx={{
                                width: '100%',
                                p: 3
                            }}
                        >
                            <Typography variant="subtitle1" noWrap>
                                Total User
                            </Typography>
                            <Typography variant="h4" noWrap>
                                16,329
                            </Typography>
                        </Box>
                        <Box pt={2}>
                            <Chart
                                options={Box1Options}
                                series={Box2Data}
                                type="line"
                                height={100}
                            />
                        </Box>
                    </Paper>
                    <Paper sx={{
                        mb: 4,
                        mx: 2,
                        width: '25%'
                    }}  elevation={2}>
                        <Box
                            sx={{
                                width: '100%',
                                p: 3
                            }}
                        >
                            <Typography variant="subtitle1" noWrap>
                                Sample User
                            </Typography>
                            <Typography variant="h4" noWrap>
                                12,356
                            </Typography>
                        </Box>
                        <Box pt={2}>
                            <Chart
                                options={Box1Options}
                                series={Box2Data}
                                type="line"
                                height={100}
                            />
                        </Box>
                    </Paper>
                    <Box sx={{
                        mb: 4,
                        ml: 2,
                        width: '25%'
                    }}>
                        <Paper sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }} elevation={2} >
                            <Chart
                                height={300}
                                options={chartOptions}
                                series={chartSeries}
                                type="donut"
                            />
                        </Paper>
                    </Box>

                </Box>

                <Paper sx={{
                    p: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} elevation={2} >
                    <Chart
                        height={300}
                        width={600}
                        options={barChartOptions}
                        series={barChartSeries}
                        type="bar"
                    />
                    <Chart
                        height={300}
                        width={600}
                        options={lineAreaOption}
                        series={lineSeries}
                        type="area"
                    />
                </Paper>
            </Box>

        </ThemeProvider>
    );
};
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import {
    ADMINDASHBOARD,
    LOGIN,
    NEW_APPLICATION, PRINT_APPLICATION,
    PROGRAMME_STATISTICS,
    REPORTS,
    SIGN_IN, USERPROFILE,
    VIEW_APPLICATION
} from "../constants/routes";
import {Login} from "../screens/Login";
import {SignIn} from "../screens/SignIn";
import {MenuContainer} from "../screens/MenuContainer";
import {AdminDashboard} from "../screens/AdminDashboard";
import {ViewApplication} from "../screens/ViewApplication";
import {NewApplication} from "../screens/NewApplication";
import {Reports} from "../screens/Reports";
import {ProgrammeStatistics} from "../screens/ProgrammeStatistics";
import {UserProfile} from "../screens/UserProfile";
import {Logout} from "../screens/Logout";
import {PrintApplication} from "../screens/PrintApplication";

const Routes = () => {
    const { token } = useAuth();

    // Define public routes accessible to all users
    const routesForPublic = [


    ];

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute/>, // Wrap the component in ProtectedRoute
            children: [
                {
                    path: "/",
                    element: <MenuContainer/>,
                    children: [
                        {
                            path: ADMINDASHBOARD,
                            element: <AdminDashboard/>,
                        },
                        {
                            path: VIEW_APPLICATION,
                            element: <ViewApplication/>,
                        },

                        {
                            path: NEW_APPLICATION,
                            element: <NewApplication/>,
                        },
                        {
                            path: REPORTS,
                            element: <Reports/>,
                        },
                        {
                            path: PROGRAMME_STATISTICS,
                            element: <ProgrammeStatistics/>,
                        },
                        {
                            path: USERPROFILE,
                            element: <UserProfile/>,
                        },

                        {
                            path: "/logout",
                            element: <Logout/>,
                        },
                    ]
                },]
        },
        {
                path: PRINT_APPLICATION,
            element: <PrintApplication />,
        }
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [

        {
            path: `/${LOGIN}`,
            element: <Login/>,
        },
        {
            path: `/${SIGN_IN}`,
            element: <SignIn/>,
        },

    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;
import * as React from 'react';
import Typography from "@mui/material/Typography";
import {Alert, Box, Button, Chip, createTheme, Modal, Paper, Snackbar, Stack, TextField} from "@mui/material";
import {map} from "lodash";
import {isActive} from "../services/utils/helper";
import {styled} from "@mui/material/styles";
import {LabelWithDetails} from "./LabelWithDetails";
import {ImmiCardsType} from "../services/utils/types/types";
import Api from "../services/server/api";
import moment from "moment/moment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


interface ImmiCardsComp {
    immiCardList: Array<ImmiCardsType>,
    personID: string

}

const theme = createTheme();
const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));


export const ImmiCardsComp: React.FC<ImmiCardsComp> = ({ immiCardList, personID }) => {
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
        setOpenSuccess(false);
    }
    const [expiry, setExpiry] = React.useState(null);
    const api = new Api();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        // @ts-ignore
        const expiryDate = expiry.format('DD.MM.YYYY').toString();
        const formData = new FormData(event.currentTarget);
        let data = {personId: personID, cardNumber: formData.get('cardNumber'), status: formData.get('status'),expiryDate: expiryDate, isOriginal:false};
        api
            .addNewCards(data)
            .then((response: any) => {
                if (response && response.data) {
                    setOpenSuccess(true);
                    handleClose();
                    //replace it with api call in future.
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log(err)
                setOpenError(true);
                // Show error
                handleClose();
            });

    };






    return (
<div>         <Snackbar anchorOrigin={{vertical:'top',horizontal: "right"}}
                        open={openSuccess} autoHideDuration={6000}   onClose={handleCloseBar}>
    <Alert severity="success" sx={{width: '100%'}} onClose={handleCloseBar}>
        New Passport Has been added successfully
    </Alert>
</Snackbar>
    <Snackbar anchorOrigin={{vertical:'top',horizontal: "right"}}
              open={openError} autoHideDuration={6000} onClose={handleCloseBar}>
        <Alert severity="error" sx={{width: '100%'}} onClose={handleCloseBar}>
            Seems like an error happened while adding new passport!
        </Alert>
    </Snackbar>

        <Box>
            <Typography
                component="h1"
                variant="h5"
                color="inherit"
                noWrap
                sx={{flexGrow: 1, m: 2, fontWeight: 'bold'}}
            >
                List of IMMI13 Cards
            </Typography>
            <Button variant="contained" sx={{m: 2, fontWeight: 'bold'}} color={"secondary"} onClick={handleOpen}>Add IMMI13 Card</Button>

            <Stack spacing={2} direction="row" m={2}>
                {map(immiCardList, (i) => (
                    <Item>
                        <LabelWithDetails title="CARD NUMBER">{i.cardNumber}</LabelWithDetails>
                        <LabelWithDetails title="EXPIRY DATE">{i.expiryDate}</LabelWithDetails>
                        <Stack direction="row" spacing={1}>
                            {i.isOriginal === "true" ? <Chip label="ORGINAL" color="info"/> : <Chip label={i.status.toUpperCase()} color="secondary"/>}
                            {isActive(i.expiryDate) && <Chip label="ACTIVE" color="success"/>}
                            {!isActive(i.expiryDate) && <Chip label="EXPIRED" color="error"/>}
                        </Stack>
                    </Item>
                ))}


            </Stack>
        </Box>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Passport
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                    required
                    id="cardNumber"
                    name="cardNumber"
                    label="Card Number"
                    sx={{mt: 2}}
                />

                <TextField
                    required
                    id="status"
                    name="status"
                    label="Card Status"
                    sx={{mt: 2}}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Visa Expiry Date"
                        sx={{mt: 2}}
                        value={expiry}
                        format={"DD.MM.YYYY"}
                        onChange={(newValue) => setExpiry(newValue)}
                    />
                </LocalizationProvider>
                <Button variant="contained" sx={{m: 2, fontWeight: 'bold'}} color={"secondary"}
                        type="submit">Save</Button>
                <Button variant="contained" sx={{m: 2, fontWeight: 'bold'}} color={"error"}
                        onClick={handleClose}>Cancel</Button>

            </form>
        </Box>
    </Modal>

</div>
    );
};

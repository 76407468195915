import * as React from 'react';
import Typography from "@mui/material/Typography";
import {
    Alert,
    Box,
    Button,
    Chip,
    createTheme,
    FormControl,
    Grid,
    InputBase,
    Modal,
    Paper,
    Snackbar,
    Stack,
    TextField
} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import {map} from "lodash";
import {isActive} from "../services/utils/helper";
import {styled} from "@mui/material/styles";
import {LabelWithDetails} from "./LabelWithDetails";
import {PassportType} from "../services/utils/types/types";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment";
import Api from "../services/server/api";


interface PassportListComp {
    passportList: Array<PassportType>,
    personID: string
}

const theme = createTheme();
const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));
const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export const PassportListComp: React.FC<PassportListComp> = ({passportList, personID}) => {
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
        setOpenSuccess(false);
    }
    const [expiry, setExpiry] = React.useState(null);
    const api = new Api();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        // @ts-ignore
        const expiryDate = expiry.format('DD.MM.YYYY').toString();
        const formData = new FormData(event.currentTarget);
        let data = {personId: personID, passportNo: formData.get('passportNo'), expiryDate: expiryDate};
        api
            .addNewPassport(data)
            .then((response: any) => {
                if (response && response.data) {
                    setOpenSuccess(true);
                    handleClose();
                    //replace it with api call in future.
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log(err)
                setOpenError(true);
                // Show error
                handleClose();
            });

    };


    return (
        <div>
            <Snackbar anchorOrigin={{vertical:'top',horizontal: "right"}}
                      open={openSuccess} autoHideDuration={6000}   onClose={handleCloseBar}>
                <Alert severity="success" sx={{width: '100%'}} onClose={handleCloseBar}>
                    New Passport Has been added successfully
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{vertical:'top',horizontal: "right"}}
                open={openError} autoHideDuration={6000} onClose={handleCloseBar}>
                <Alert severity="error" sx={{width: '100%'}} onClose={handleCloseBar}>
                    Seems like an error happened while adding new passport!
                </Alert>
            </Snackbar>
            <Box>
                <Typography
                    component="h1"
                    variant="h5"
                    color="inherit"
                    noWrap
                    sx={{flexGrow: 1, m: 2, fontWeight: 'bold'}}
                >
                    List of Passports
                </Typography>
                <Button variant="contained" sx={{m: 2, fontWeight: 'bold'}} color={"secondary"} onClick={handleOpen}>Add
                    Passport</Button>

                <Stack spacing={2} direction="row" m={2}>
                    {map(passportList, (i) => (
                        <Item>
                            <LabelWithDetails title="PASSPORT NUMBER">{i.passportNo}</LabelWithDetails>
                            <LabelWithDetails title="EXPIRY DATE">{i.expiryDate}</LabelWithDetails>
                            {!isActive(i.expiryDate) && <Chip label="Expired" color="error"/>}
                            {isActive(i.expiryDate) && <Chip label="Active" color="success"/>}
                        </Item>
                    ))}
                </Stack>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Passport
                    </Typography>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <TextField
                            required
                            id="passportNo"
                            name="passportNo"
                            label="Passport Number"
                            sx={{mt: 2}}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Passport Expiry Date"
                                sx={{mt: 2}}
                                value={expiry}
                                format={"DD.MM.YYYY"}
                                onChange={(newValue) => setExpiry(newValue)}
                            />
                        </LocalizationProvider>
                        <Button variant="contained" sx={{m: 2, fontWeight: 'bold'}} color={"secondary"}
                                type="submit">Save</Button>
                        <Button variant="contained" sx={{m: 2, fontWeight: 'bold'}} color={"error"}
                                onClick={handleClose}>Cancel</Button>

                    </form>
                </Box>
            </Modal>

        </div>

    );
};

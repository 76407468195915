import * as React from 'react';
import Typography from "@mui/material/Typography";

interface LabelWithDetailsProps {
    title: string;
    children?: React.ReactNode;
}

export const LabelWithDetails: React.FC<LabelWithDetailsProps> = ({ title, children }) => {
    return (
        <div style={{padding: '10px 0px'}}>
            <Typography
                component="h1"
                variant="subtitle2"
                color="inherit"
                sx={{ fontWeight: '600' }}
            >
                {title}
            </Typography>
            <Typography
                component="h1"
                variant="subtitle2"
                color="inherit"
            >
                {children}
            </Typography>
        </div>
    );
};

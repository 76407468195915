import {Avatar, Box, Chip, createTheme, Paper, Stack, ThemeProvider} from "@mui/material";
import * as React from "react";
import {LabelWithDetails} from "../../../../components/LabelWithDetails";
import {map, reduce} from "lodash";

import * as people from '../../../../services/utils/people.json';
import * as immis from '../../../../services/utils/immi13.json';

import {getAgeByYearOfBirth, getAgeCategoryByYearOfBirth, getGenderByChar, isActive} from "../../../../services/utils/helper";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {ImmiCardsComp} from "../../../../components/immiCardsComp";
import {PassportListComp} from "../../../../components/passportListComp";
import {applicationType, userType} from "../../../../services/utils/types/types";

interface PersonalDetailsProps {
    application?:applicationType;
}

interface person {
    NAME: string
}


const theme = createTheme();
const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

export const PersonalDetails: React.FC<PersonalDetailsProps> = (props) => {
    const queryParameters = new URLSearchParams(window.location.search)
    let userList: userType[], user: userType | undefined = {};
    if(props&&props.application&&props.application.person){
        userList = props.application.person;
        user = userList.find(person =>  person.relationship === "MAIN APPLICANT");
    }
    let immiList: any = [];
    let passportList: any = [];
    let userID="";
    if(user&&user.id){
        userID = user.id;
    }
    if (user && user.immi13) {
        immiList = user.immi13;
    }
    if (user && user.passport) {
        passportList = user.passport;
    }


    if (!user) {
        console.log('url is not valid!')
    }
    const personalDetails = [
        {title: `Full Name`, detail: user ? user.name ? user.name : '-' : '-'},
        {title: `Calling Num`, detail: user ? user.callingNum ? user.callingNum : '-' : '-'},
        // { title: `Batch`, detail: '1' },
        {title: `File No.`, detail: user ? user.fileNo ? user.fileNo : '-' : '-'},
        {title: `Gender`, detail: user ? user.gender ? getGenderByChar(user.gender) : '-' : '-'},
        {title: `Year of Birth`, detail: user ? user.yearOfBirth ? user.yearOfBirth : '-' : '-'},
        {title: `Age`, detail: getAgeByYearOfBirth(user ? user.yearOfBirth ? user.yearOfBirth : '0' : '0')},
        {
            title: `Age Category`,
            detail: getAgeCategoryByYearOfBirth(user ? user.yearOfBirth ? user.yearOfBirth : '0' : '0')
        },
        {title: `document`, detail: user ? user.typeOfDocument ? user.typeOfDocument : '-' : '-'},
        {title: `IMM13 STATUS`, detail: user ? user.immi13Status ? user.immi13Status : '-' : '-'},
        {title: `APPLICANT STATUS`, detail: user ? user.applicationStatus ? user.applicationStatus : '-' : '-'},
        {title: `Notes`, detail: user ? user.notes ? user.notes : '-' : '-'},
        {title: `UN STATUS (UNHCR CARD NO.)`, detail: user ? user.unStatus ? user.unStatus : '-' : '-'},
        {
            title: `UNHRC CARD EXPIRY DATE`,
            detail: user ? user.unhrcCardExpiryDate ? user.unhrcCardExpiryDate : '-' : '-'
        },
        {title: `MOBILE NO.`, detail: user ? user.mobileNo ? user.mobileNo : '-' : '-'},
        {title: `EMAIL ADDRESS`, detail: user ? user.emailAddress ? user.emailAddress : '-' : '-'},
        {
            title: `DISTRICT`,
            detail: user ? user.address ? user.address.DISTRICT ? user.address.DISTRICT : '-' : '-' : '-'
        },
        {title: `STATE`, detail: user ? user.address ? user.address.STATE ? user.address.STATE : '-' : '-' : '-'},
        {
            title: `Detailed Address`,
            detail: user ? user.address ? user.address.DETAIL_ADDRESS ? user.address.DETAIL_ADDRESS : '-' : '-' : '-'
        },
        {title: `NOTE(S) / REMARK(S)`, detail: user ? user.remarks ? user.remarks : '-' : '-'},
    ]

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    m: 2
                }}
            >
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto auto',
                }}>

                    {map(personalDetails, (i) => (
                        <LabelWithDetails title={i.title.toUpperCase()}>{i.detail}</LabelWithDetails>
                    ))}

                </div>
            </Box>


            <PassportListComp passportList={passportList} personID={userID}></PassportListComp>



            <ImmiCardsComp immiCardList={immiList} personID={userID}></ImmiCardsComp>

        </ThemeProvider>
    );
};
import {
    Box, Button,
    Icon,
    IconButton, InputBase, LinearProgress, Menu, MenuItem,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import React, {SetStateAction, useEffect, useState} from "react";
import {
    faEllipsisV,
    faEye,
    faEyeLowVision,
    faEyeSlash,
    faFolderMinus,
    faFolderOpen, faFrownOpen
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faker} from "@faker-js/faker";
import {createSearchParams, useNavigate} from "react-router-dom";
import {PRINT_APPLICATION, USERPROFILE} from "../constants/routes";
import SearchIcon from '@mui/icons-material/Search';
import * as people from '../services/utils/people.json';
import {getGenderByChar} from "../services/utils/helper";
import Api from "../services/server/api";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": {"& th": {paddingLeft: 0, paddingRight: 0}},
    },
    "& tbody": {
        "& tr": {"& td": {paddingLeft: 0, textTransform: "capitalize"}},
    },
}));

const SimpleTable = () =>{
const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(10);
const [anchorEl, setAnchorEl] = React.useState(null);
const [isLoading, setLoading] = React.useState(false);
const open = Boolean(anchorEl);
const [data, setData] = useState([{}]);

let subscribarList = [{}];

const fetchUser =   (params: any) => {
    setLoading(true);
    const api = new Api();
    api
        .getUserList(params)
        .then((response) => {
            subscribarList = response.data.data;
            setData(subscribarList);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setData([]);
            setLoading(false);
        });
};
        useEffect(() => {
            fetchUser({perPage:3000});
        }, []);
let navigate = useNavigate();



const goToProfile = (appId: string) => {

    navigate({
        pathname: '/' + USERPROFILE,
        search: createSearchParams({
            id: appId
        }).toString()
    });
}

const handleChangePage = (_: any, newPage: SetStateAction<number>) => {
    setPage(newPage);
};


const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
}

const handleClose = () => {
    setAnchorEl(null);
}

const handleChangeRowsPerPage = (event: { target: { value: string | number; }; }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
};
const handleSearch =   (event: any) => {
    const delayDebounceFn = setTimeout(() => {
        fetchUser({search: event.target.value, perPage: 3000});
    }, 2000)
    return () => clearTimeout(delayDebounceFn)
}


return (
    <Box width="100%" overflow="auto">
        {isLoading && <LinearProgress color="secondary"/>}

        <InputBase
            sx={{ml: 1, alignItems: 'center'}}
            placeholder="Search applicants"
            onChange={handleSearch}
            inputProps={{'aria-label': 'Search Applicants'}}/>
        <IconButton type="button" sx={{p: '10px'}} aria-label="search">
            <SearchIcon/>
        </IconButton>
        <StyledTable>
            <TableHead>
                <TableRow>
                    <TableCell align="left">FULL NAME</TableCell>
                    <TableCell align="center">CALLING NUM</TableCell>
                    <TableCell align="center">GENDER</TableCell>
                    <TableCell align="center">TYPE OF DOC</TableCell>
                    <TableCell align="center">STATUS</TableCell>
                    <TableCell align="right">ACTION</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((subscriber: any, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">{subscriber.name}</TableCell>
                            <TableCell align="center">{subscriber.callingNum}</TableCell>
                            <TableCell
                                align="center">{getGenderByChar(subscriber.gender)}</TableCell>
                            <TableCell align="center">{subscriber.typeOfDocument}</TableCell>
                            <TableCell align="center">{subscriber.applicationStatus}</TableCell>
                            <TableCell align="right">
                                <IconButton
                                    aria-label="More"
                                    aria-owns={open ? "long-menu" : undefined}
                                    aria-haspopup="true"
                                    onClick={() => {
                                        goToProfile(subscriber.applicationId)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFolderOpen as IconProp}/>
                                </IconButton>

                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </StyledTable>

        <TablePagination
            sx={[
                {
                    '& p': {
                        margin: 0
                    },
                },
            ]}
            page={page}
            component="div"
            rowsPerPage={rowsPerPage}
            count={data.length}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            nextIconButtonProps={{"aria-label": "Next Page"}}
            backIconButtonProps={{"aria-label": "Previous Page"}}
        />
    </Box>
);
}
;

export default SimpleTable;

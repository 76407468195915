import {Avatar, Box, createTheme, FormLabel, Paper, ThemeProvider} from "@mui/material";
import * as React from "react";
import {forEach, indexOf, map} from "lodash";
import {LabelWithDetails} from "../../../../components/LabelWithDetails";
import Divider from "@mui/material/Divider";
import * as people from "../../../../services/utils/people.json";
import {getFamilyMembersDetails} from "../../../../services/utils/helper";
import {PassportListComp} from "../../../../components/passportListComp";
import {ImmiCardsComp} from "../../../../components/immiCardsComp";
import {applicationType, userType} from "../../../../services/utils/types/types";



interface FamilyMembersProps {
application?:applicationType;
}

const theme = createTheme();

export const FamilyMembers: React.FC<FamilyMembersProps> = (props) => {
    let userList: userType[], user: userType | undefined = {};
    let newArr;
    if(props&&props.application&&props.application.person&&props.application.person){
        userList = props.application.person;
        userList = userList.filter(person =>person.relationship?(person.relationship.toLowerCase()!=="spouse"&&person.relationship.toLowerCase()!=="main applicant"):false);
         newArr = userList.map((element:userType, index) => {
            const item = {
                doms:getFamilyMembersDetails(element),
                immi13: element.immi13,
                passport:element.passport,
                userID: element.id
            }
            return item;
        } );

    }


    if(!user){
        console.log('url is not valid!')
    }









    return (
        <ThemeProvider theme={theme}>

            {map(newArr, (item, index) => {
             // @ts-ignore
                // @ts-ignore
                // @ts-ignore
                // @ts-ignore
                return <div>
                 <FormLabel sx={{m:2}}>Family Member {index +1}</FormLabel>
                 <Box
                    sx={{
                        m: 2
                    }}
                >
                    <div style={{display: 'grid',
                        gridTemplateColumns: 'auto auto auto',}}>
                        {map(item.doms, (i) => (
                            <LabelWithDetails title={i.title.toUpperCase()}>{i.detail}</LabelWithDetails>
                        ))}
                    </div>
                </Box>

                 {/*List of passports*/}

                 <PassportListComp passportList={item.passport?item.passport:[]} personID={item.userID?item.userID:""}></PassportListComp>

                 {/*List of IMMI13 cards*/}
                 <ImmiCardsComp immiCardList={item.immi13?item.immi13:[]} personID={item.userID?item.userID:""}></ImmiCardsComp>
                 <Divider variant="fullWidth" sx={{ borderBottomWidth: 5 }}/>

             </div>
            })}


        </ThemeProvider>
    );
};
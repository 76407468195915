import {AccountSettings} from "./UserProfileTabs/AccountSettings";
import {FamilyMembers} from "./UserProfileTabs/FamilyMembers";
import {PersonalDetails} from "./UserProfileTabs/PersonalDetails";
import {SpouseDetails} from "./UserProfileTabs/SpouseDetails";
interface userType {
    name?: string,
    callingNum?: string,
    mobileNo?: string,
    emailAddress?: string,
    applicationStatus?: string,
    relationship?:string
}

interface applicationType {
    name?: string,
    callingNum?: string,
    mobileNo?: string,
    emailAddress?: string,
    applicationStatus?: string,
    person: userType[] | null,
}
export  const getUserProfileTabs = (application: applicationType) => {
    const userProfileTabs = [
        { text: "Personal Details", children: <PersonalDetails application={application}/> },
        { text: "Spouse Details", children: <SpouseDetails application={application}/> },
        { text: "Family Members", children: <FamilyMembers application={application}/> },
        { text: "Account Settings", children: <AccountSettings application={application}/> },
    ];
    return userProfileTabs;
}

